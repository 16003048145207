import { createWeb3Modal, useWeb3Modal } from '@web3modal/wagmi/react'
import { formatRelative, intervalToDuration, min } from 'date-fns'
import { cloneDeep, isEqual } from 'lodash'
import React, { CSSProperties, ReactElement, useEffect, useMemo, useRef, useState } from 'react'
import styled, { createGlobalStyle, css, keyframes } from 'styled-components'
import { Address, createPublicClient, createWalletClient, custom, erc20Abi, getContract, http, maxUint256, parseEther, parseUnits } from 'viem'
import { waitForTransactionReceipt } from 'viem/actions'
import { useAccount, useDisconnect } from 'wagmi'
import WaveSvg from '../animation/Wave.svg'
import WaveSvg2 from '../animation/Wave2.svg'
import WaveSvg3 from '../animation/Wave3.svg'
import Radom, { IDiscountCode, IManagedPaymentMethodDetails, IManagedPaymentStatus, IManagedTokenDelegatePaymentMethodDetails, InputFieldWithValue, PriceQuote } from '../api/Radom'
import Checkmark from '../icons/Checkmark'
import Copy from '../icons/Copy'
import GasPump from '../icons/GasPump'
import { formatSubscriptionDuration, RADOM_COLORS } from '../util/Constants'
import { getMethod, isAutopayEnabled, ManagedPaymentMethod, NetworkToChainMap, projectId, wagmiConfig } from '../util/Managed'
import { convertValue, getTotalSum } from '../util/Products'
import { generateQRCode } from '../util/QRCode'
import { Token } from '../util/Tokens'
import { formatNumber } from '../util/Util'
import { ScaleIn } from './Animations'
import { PrimaryButton, SecondaryButton } from './Button'
import Checkbox from './Checkbox'
import { CheckoutData } from './Checkout'
import { Cookie, InputLabel, TextInput } from './Input'
import SolanaWallet from './SolanaWallet'
import Spinner from './Spinner'

createWeb3Modal({
  wagmiConfig,
  projectId
})

export function formatSecondsToMinutesAndSecondsString(totalSeconds: number): string {
  const duration = intervalToDuration({ start: 0, end: totalSeconds * 1000 })

  const zeroPad = (num): string => String(num).padStart(2, '0')

  const formatted = [
    duration.days,
    duration.hours,
    duration.minutes,
    duration.seconds
  ]
    .filter((a, i) => i <= 1 ? !!a : true)
    .map(zeroPad)
    .join(':')

  return formatted
}

const Styles = createGlobalStyle`
  @property --angle {
    syntax: '<angle>';
    initial-value: 90deg;
    inherits: true;
  }

  :root {
    --d: 2500ms;
    --c1: rgba(0, 0, 0, 0.5);
    --c2: rgba(0, 0, 0, 0.1);
  }
`

const ConnectedWalletContainer = styled.div`
  box-shadow: 0 0 3px ${RADOM_COLORS.GRAY_DARK};
  border-radius: 5px;
`

const borderRotate = keyframes`
  100% {
    --angle: 440deg;
  }
`

export const QRCodeWrapper = styled.div`
  border-radius: 3px;
  overflow: hidden;
  width: fit-content;
  box-shadow: 0 0 3px ${RADOM_COLORS.GRAY_DARK};
  transition: 0.2s ease all;
  cursor: pointer;

  &:active {
    transform: scale(0.99);
  }
`

export const QRCodeWrapperInner = styled.div`
  width: fit-content;
  border: 3px solid;
  border-image: conic-gradient(from var(--angle), var(--c2), var(--c1) 0.1turn, var(--c1) 0.15turn, var(--c2) 0.25turn) 30;
  animation: ${borderRotate} var(--d) linear infinite forwards;
  font-size: 0;
`

export const WrapperIn = keyframes`
  from {
    transform: translateY(-25%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`

const WrapperAnimation = css`
  animation: ${WrapperIn} 0.2s ease;
`

const Wrapper = styled.div<{ disableAnimation?: boolean }>`
  flex-grow: 1;
  ${({ disableAnimation }) => !disableAnimation && WrapperAnimation}
`

const Container = styled.div<{ disableAnimation?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  ${({ disableAnimation }) => !disableAnimation && WrapperAnimation}
  // animation: ${WrapperIn} 0.2s ease;
`

const CheckmarkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  min-height: 20px;
  border-radius: 100%;
  background-color: ${RADOM_COLORS.NICE_GREEN};
  animation: ${ScaleIn} .25s ease;
`

export const TokenSelector = styled.div<{ isSelected: boolean, isDisabled: boolean, hoverEffect?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 15px;
  border-radius: ${({ hoverEffect }) => hoverEffect ? '0px' : '5px'};
  cursor: pointer;
  border-bottom: 1px solid;
  ${({ isSelected }) => isSelected && 'border: 1px solid;'}
  border-color: ${({ isSelected }) => isSelected ? RADOM_COLORS.NICE_GREEN : RADOM_COLORS.GRAY_DARK};
  box-shadow: ${({ isSelected }) => isSelected && `0 0 3px ${RADOM_COLORS.NICE_GREEN}`};
  opacity: ${({ isSelected }) => isSelected ? '1' : '0.3'};
  transition: 0.2s ease all;
  user-select: none;

  ${({ isSelected }) => isSelected && `
    position: sticky;
    top: 0;
    bottom: 0;
    background: white;
    z-index: 1;
  `}

  ${({ isDisabled }) => isDisabled && 'opacity: 0.5;'}

  &:hover {
    opacity: ${({ isSelected }) => isSelected ? 1 : 0.6};
    ${({ hoverEffect }) => hoverEffect && `
      background-color: ${RADOM_COLORS.GRAY_LIGHTEST};
    `}
  }

  &:active {
    opacity: ${({ isSelected }) => isSelected ? 1 : 0.8};
    transform: scale(0.98);
  }
`

export const WaveContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: -1;
  overflow: hidden;
`

const WaveAnim = keyframes`
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
`

export const Wave = styled.div`
  background: url(${WaveSvg as string}) repeat-x;
  position: absolute;
  width: 6400px;
  height: 50%;
  top: calc(50% - 100px);
  animation: ${WaveAnim} 18s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite;
`

const WaveAnim2 = keyframes`
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -3200px;
  }
`

export const Wave2 = styled(Wave)`
  background: url(${WaveSvg2 as string}) repeat-x;
  animation: ${WaveAnim2} 15s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite;
`
const WaveAnim3 = keyframes`
  0% {
    margin-left: -800px;
  }
  100% {
    margin-left: -2400px;
  }
`

export const Wave3 = styled(Wave)`
  background: url(${WaveSvg3 as string}) repeat-x;
  animation: ${WaveAnim3} 6s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite;
`

const Expand = keyframes`
  0% {
    transform: scaleY(0%);
  }
  100% {
    transform: scaleY(100%);
  }
`

const PaymentDetectedContainer = styled.div`
  animation: ${Expand} 0.2s cubic-bezier(0, 1, 0, 1);
  transform-origin: 0 0;
  border-radius: 15px;
  border: 1px solid ${RADOM_COLORS.GRAY_MED};
  overflow: hidden;
`

interface Props {
  onClick: () => void
  style?: CSSProperties
  iconColor?: string
}

export const CopyButton = ({ onClick, style, iconColor = RADOM_COLORS.GRAY_DARKEST }: Props): ReactElement => {
  const [copied, setCopied] = useState(false)

  useEffect(() => {
    if (copied) {
      const timeout = setTimeout(() => setCopied(false), 3000)
      return () => clearTimeout(timeout)
    }
  }, [copied])

  return <SecondaryButton
    onClick={() => { setCopied(true); onClick() }}
    style={{
      padding: '2px 4px',
      fontSize: 10,
      textTransform: 'uppercase',
      background: RADOM_COLORS.GRAY_MED,
      color: RADOM_COLORS.GRAY_DARKEST,
      cursor: 'pointer',
      borderRadius: 5,
      display: 'flex',
      alignItems: 'center',
      gap: 2,
      border: 'none',
      ...style
    }}>
    <span>{copied ? 'Copied' : 'Copy'}</span>
    <Copy style={{ height: 10 }} stroke={iconColor} />
  </SecondaryButton>
}

export interface CheckoutParams {
  selfCustodial?: {
    useRadomBalance: boolean
    selectedChainId: number
    selectedToken: Token
  }
  managed?: {
    selectedMethod: ManagedPaymentMethod
  }
  completeTokenDelegate?: {
    paymentId: string
    signature: string
  }
  priceQuotes: PriceQuote[]
  subscriptionOptions?: {
    emailInvoice?: {
      subscriptionType: SubscriptionType.EmailInvoice
      emailAddress: string

    }
    autopay?: {
      subscriptionType: SubscriptionType.Autopay
      address: string
    }
  }
}

interface IProps {
  checkoutData: CheckoutData
  currency: string
  isPreProcessingPayment: boolean
  isSuccess: boolean
  managedPaymentDetails?: IManagedPaymentMethodDetails
  tokenDelegatePaymentDetails?: IManagedTokenDelegatePaymentMethodDetails
  successElement: React.ReactNode
  inputsElement?: React.ReactNode
  errorMessage?: string
  discount?: IDiscountCode
  onCheckout: (params: CheckoutParams) => void
  onQuoteUpdate: () => void
  posMode?: boolean
  onInputFieldsChange?: (inputs: InputFieldWithValue[]) => void
  disableAnimation?: boolean
  paymentStatus?: IManagedPaymentStatus
}

enum SubscriptionCheckoutState {
  Select,
  Confirm,
  Allowance,
  EmailInvoicePay
}

export enum SubscriptionType {
  EmailInvoice,
  Autopay
}

const PayWidget = (props: IProps): React.ReactElement => {
  const [localCheckoutData, setLocalCheckoutData] = useState({} as any)

  const [selectedMethod, setSelectedMethod] = useState<ManagedPaymentMethod>()

  const [priceQuotes, setPriceQuotes] = useState<PriceQuote[]>([])
  const [quoteExpiryTimestamp, setQuoteExpiryTimestamp] = useState<Date>(new Date())
  const [quoteSecondsRemaining, setQuoteSecondsRemaining] = useState(0)
  const [isLoadingPriceQuotes, setIsLoadingPriceQuotes] = useState(false)

  const { disconnect } = useDisconnect()
  const { address, isConnected, connector } = useAccount()
  const { open } = useWeb3Modal()

  const [localInputFields, setLocalInputFields] = useState([] as InputFieldWithValue[])
  const inputRefs = useRef<Array<HTMLInputElement | null>>([])

  const [subscriptionEmailAddress, setSubscriptionEmailAddress] = useState('')

  useEffect(() => {
    if (props.checkoutData.inputFields) {
      inputRefs.current = inputRefs.current.slice(0, props.checkoutData.inputFields.length)
      setLocalInputFields(props.checkoutData.inputFields)
    }
  }, [props.checkoutData.inputFields])

  const getPriceQuotes = (): void => {
    props.onQuoteUpdate()
    setIsLoadingPriceQuotes(true)

    const fromCurList = [
      ...props.checkoutData.products?.map(p => p.product.currency) ?? [],
      ...props.checkoutData.lineItems?.map(p => p.currency ?? 'USD') ?? [],
      props.currency
    ]
    const { managed } = props.checkoutData.gateway
    if (managed) {
      fromCurList.push('USD', ...managed.methods.map(t => getMethod(t.network, t.token).ticker))
    }
    const fromCur = [...new Set(fromCurList)]

    if (fromCur.length === 0) return

    Radom
      .getPriceQuotes(fromCur, fromCur)
      .then(priceQuotes => {
        const minTimestamp = min(priceQuotes.map(p => new Date(p.expiryTimestamp)))
        setQuoteExpiryTimestamp(minTimestamp)
        setQuoteSecondsRemaining((minTimestamp.getTime() - new Date().getTime()) / 1000)
        setPriceQuotes(priceQuotes)

        setIsLoadingPriceQuotes(false)
      })
  }

  const [totalSum, recurringSum] = useMemo(() => {
    return getTotalSum(props.checkoutData, priceQuotes, props.currency, props.discount)
  }, [props.checkoutData, priceQuotes, props.currency, props.discount])

  const recurringInterval = (): number => {
    if (props.checkoutData.products) {
      const g = props.checkoutData.products.find(p => p.product.chargingIntervalSeconds > 0)
      if (g) {
        return g.product.chargingIntervalSeconds / 60 / 60 / 24
      }
    }

    if (props.checkoutData.lineItems) {
      const g = props.checkoutData.lineItems.find(p => p.chargingIntervalSeconds > 0)
      if (g) {
        return g.chargingIntervalSeconds / 60 / 60 / 24
      }
    }

    return 0
  }

  const nextBillingAt = (): string => {
    if (props.checkoutData.products) {
      const g = props.checkoutData.products.find(p => p.product.chargingIntervalSeconds > 0)
      if (g?.product.nextBillingDateAt) {
        return formatRelative(new Date(g.product.nextBillingDateAt), new Date())
      }
    }

    if (props.checkoutData.lineItems) {
      const g = props.checkoutData.lineItems.find(p => p.chargingIntervalSeconds > 0)
      if (g?.nextBillingDateAt) {
        return formatRelative(new Date(g.nextBillingDateAt), new Date())
      }
    }

    return formatRelative(
      new Date(new Date().getTime() + recurringInterval() * 60 * 60 * 24 * 1000),
      new Date()
    )
  }

  useEffect(() => {
    if (
      isEqual(localCheckoutData.gateway, props.checkoutData.gateway) &&
      isEqual(localCheckoutData.products, props.checkoutData.products) &&
      isEqual(localCheckoutData.lineItems, props.checkoutData.lineItems) &&
      isEqual(localCheckoutData.currency, props.checkoutData.currency) &&
      isEqual(localCheckoutData.total, props.checkoutData.total)
    ) {
      return
    }

    if (props.checkoutData.gateway.managed) {
      const method = props.checkoutData.gateway.managed.methods[0]
      if (method) {
        setSelectedMethod(getMethod(method.network, method.token))
      }

      setLocalCheckoutData(cloneDeep(props.checkoutData))
      getPriceQuotes()
    }
  }, [props.checkoutData])

  useEffect(() => {
    getPriceQuotes()
  }, [props.checkoutData.products?.length])

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (quoteExpiryTimestamp.getTime() <= new Date().getTime()) {
        getPriceQuotes()
      }

      setQuoteSecondsRemaining((quoteExpiryTimestamp.getTime() - new Date().getTime()) / 1000)
    }, 1000)

    return () => clearInterval(myInterval)
  }, [quoteExpiryTimestamp])

  const [managedPaymentQrCode, setManagedPaymentQrCode] = useState('')
  const [isManagedEVMTxLoading, setIsManagedEVMTxLoading] = useState(false)

  const [errorMessage, setErrorMessage] = useState<string>()

  const [managedSubscriptionState, setManagedSubscriptionState] = useState<SubscriptionCheckoutState>()
  const [selectedSubscriptionType, setSelectedSubscriptionType] = useState<SubscriptionType>()
  const [receiveEmailNotifications, setReceiveEmailNotifications] = useState(false)

  const generateManagedPaymentQrCode = async (): Promise<void> => {
    if (!props.managedPaymentDetails) {
      return
    }

    const [qrCodeData] = await generateQRCode(props.managedPaymentDetails)
    setManagedPaymentQrCode(qrCodeData)
  }

  const onClickOpenWallet = async (): Promise<void> => {
    if (!props.managedPaymentDetails) {
      return
    }

    switch (props.managedPaymentDetails.network) {
    case 'Bitcoin':
    case 'Solana':
    case 'Zcash':
    case 'ZcashTestnet':
    case 'Polkadot':
    case 'PolkadotTestnet':
    case 'PolkadotAssetHub':
    case 'PolkadotAssetHubTestnet':
      break
    case 'Ethereum':
    case 'SepoliaTestnet':
    case 'Polygon':
    case 'PolygonTestnet':
    case 'BNB':
    case 'BNBTestnet':
    case 'Base':
    case 'Arbitrum':
    case 'Avalanche':
      try {
        setErrorMessage('')
        if (!address) {
          await open()
          return
        }

        setIsManagedEVMTxLoading(true)

        const chain = NetworkToChainMap[props.managedPaymentDetails.network] as any
        const provider: any = await connector?.getProvider()

        const signer = createWalletClient({
          chain,
          transport: custom(provider)
        })

        if (connector?.switchChain) {
          const chainId = await connector.getChainId()
          if (chainId !== chain.id) {
            await connector?.switchChain({ chainId: chain.id })
          }
        }

        if (props.managedPaymentDetails.token) {
          const method = getMethod(props.managedPaymentDetails.network, props.managedPaymentDetails.token)

          const contract = getContract({
            address: props.managedPaymentDetails.token as any,
            abi: erc20Abi,
            client: signer
          })

          try {
            const balance = await contract.read.balanceOf([address])
            const decimals = await contract.read.decimals()
            const parsedAmount = parseUnits(totalSum.toString(), decimals)
            if (balance < parsedAmount) {
              setIsManagedEVMTxLoading(false)
              return setErrorMessage(`Your wallet does not have enough ${selectedMethod?.name} for payment.`)
            }
          } catch (err) {
            console.error('Failed to get token balance using injected provider', err)
          }

          await contract.write.transfer([
            props.managedPaymentDetails.paymentAddress as Address,
            parseUnits(props.managedPaymentDetails.amount, method.tokenInfo?.decimals ?? 6)
          ], { account: address, chain })
        } else {
          const client = createPublicClient({
            chain,
            transport: custom(provider)
          })

          try {
            const balance = await client.getBalance({ address })
            if (balance < parseEther(props.managedPaymentDetails.amount)) {
              setIsManagedEVMTxLoading(false)
              return setErrorMessage('Your wallet does not have enough ether for payment.')
            }
          } catch (err) {
            console.error('Failed to get balance using injected provider', err)
          }

          if (!signer) return

          await signer.sendTransaction({
            account: address,
            chain,
            to: props.managedPaymentDetails.paymentAddress as Address,
            value: parseEther(props.managedPaymentDetails.amount)
          })
        }
      } catch (err) {
        setIsManagedEVMTxLoading(false)
        setErrorMessage('Failed to open wallet. Please ensure you have enough funds.')
        console.error(err)
      }
      break
    default:
      console.error('No wallet')
    }
  }

  const [isSigningMessage, setIsSigningMessage] = useState(false)
  const [isSettingAllowance, setIsSettingAllowance] = useState(false)
  const [allowSkipAllowance, setAllowSkipAllowance] = useState(false)
  const setManagedSubscriptionAllowance = async (): Promise<void> => {
    if (selectedMethod?.tokenInfo?.tokenType !== 'evm') {
      return
    }

    if (!address) {
      await open()
      return
    }

    setIsSettingAllowance(true)

    try {
      if (!address) return

      const chain = NetworkToChainMap[selectedMethod.tokenInfo.networkName] as any
      const provider: any = await connector?.getProvider()

      const publicClient = createPublicClient({
        chain,
        transport: http()
      })

      const signer = createWalletClient({
        chain,
        transport: custom(provider)
      })

      if (connector?.switchChain) {
        const chainId = await connector.getChainId()
        if (chainId !== chain.id) {
          await connector?.switchChain({ chainId: chain.id })
        }
      }

      const contract = getContract({
        address: selectedMethod.tokenInfo.tokenAddress as any,
        abi: erc20Abi,
        client: { public: publicClient, wallet: signer }
      })
      const balance = await contract.read.balanceOf([address])

      const parsedAmount = parseUnits(totalSum.toString(), selectedMethod.tokenInfo.decimals)
      if (balance < parsedAmount) {
        setIsSettingAllowance(false)
        return setErrorMessage(`Your wallet does not have enough ${selectedMethod?.name} for payment.`)
      }

      const allowance = await contract.read.allowance([
        address,
        selectedMethod.tokenInfo.subscriptionContract as Address
      ])

      if (allowance <= BigInt(0) || allowance < parsedAmount * BigInt(300)) {
        if (selectedMethod.name === 'Tether' && allowance > 0) {
          const hash = await contract.write.approve(
            [selectedMethod.tokenInfo.subscriptionContract as any, 0n],
            {
              account: address as any,
              chain
            }
          )

          if (hash) {
            try {
              await waitForTransactionReceipt(signer, {
                hash,
                retryCount: 30,
                retryDelay: 1000
              })
            } catch (err) {
              console.error('Failed to confirm allowance tx', err)
            }
          }
        }

        const hash = await contract.write.approve(
          [selectedMethod.tokenInfo.subscriptionContract as any, maxUint256],
          {
            account: address as any,
            chain
          }
        )

        if (hash) {
          try {
            console.log('Waiting for tx receipt')
            await waitForTransactionReceipt(signer, {
              hash,
              retryCount: 30,
              retryDelay: 1000
            })
          } catch (err) {
            console.error('Failed to confirm allowance tx', err)
          }
        }
      }

      setIsSettingAllowance(false)
      setManagedSubscriptionState(SubscriptionCheckoutState.Confirm)
    } catch (err) {
      setIsSettingAllowance(false)
      setErrorMessage('Failed to set allowance')
      console.error(err)
    }
  }

  useEffect(() => {
    if (props.managedPaymentDetails) {
      generateManagedPaymentQrCode()

      if (managedSubscriptionState !== undefined) {
        setManagedSubscriptionState(SubscriptionCheckoutState.EmailInvoicePay)
      }
    }
  }, [props.managedPaymentDetails])

  const [gasCost, setGasCost] = useState('')
  useEffect(() => {
    if (managedSubscriptionState === undefined) {
      setSelectedSubscriptionType(undefined)
      setReceiveEmailNotifications(false)
    }

    if (selectedMethod?.tokenInfo && selectedMethod.tokenInfo.tokenType === 'evm') {
      Radom.estimateTokenTranferGas(selectedMethod.tokenInfo.networkName, selectedMethod.tokenInfo.tokenAddress)
        .then(g => {
          setGasCost(g.gasCost)
        })
        .catch(err => console.error(err))
    }
  }, [managedSubscriptionState])

  const [isLoadingAllowance, setIsLoadingAllowance] = useState(false)
  const computeAllowanceStep = async(): Promise<void> => {
    if (!props.tokenDelegatePaymentDetails) {
      return
    }

    if (selectedMethod?.tokenInfo?.tokenType !== 'evm') {
      return
    }

    if (!address || !connector) {
      await open()
      return
    }

    setIsLoadingAllowance(true)
    setErrorMessage('')

    let signer
    const chain = NetworkToChainMap[props.tokenDelegatePaymentDetails.network] as any

    const publicClient = createPublicClient({
      chain,
      transport: http()
    })

    try {
      const provider: any = await connector?.getProvider()

      signer = createWalletClient({
        chain,
        transport: custom(provider)
      })

      if (connector?.switchChain) {
        const chainId = await connector.getChainId()
        if (chainId !== chain.id) {
          await connector?.switchChain({ chainId: chain.id })
        }
      }
    } catch (err) {
      setIsLoadingAllowance(false)
      setErrorMessage(err.toString())
      return
    }

    const contract = getContract({
      address: selectedMethod.tokenInfo.tokenAddress as any,
      abi: erc20Abi,
      client: { public: publicClient, wallet: signer }
    })

    try {
      if (!address) return
      const allowance = await contract.read.allowance([
        address,
        selectedMethod.tokenInfo.subscriptionContract as Address
      ])
      const parsedAmount = parseUnits(totalSum.toString(), 6)
      if (allowance >= parsedAmount) {
        setAllowSkipAllowance(true)
      }
    } catch (err) {
      setAllowSkipAllowance(true)
    }

    setIsLoadingAllowance(false)
    setManagedSubscriptionState(SubscriptionCheckoutState.Allowance)
  }

  useEffect(() => {
    if (props.tokenDelegatePaymentDetails) {
      computeAllowanceStep()
    } else if (managedSubscriptionState === SubscriptionCheckoutState.Confirm) {
      setManagedSubscriptionState(SubscriptionCheckoutState.Select)
    }
  }, [props.tokenDelegatePaymentDetails])

  useEffect(() => {
    setErrorMessage(props.errorMessage)
  }, [props.errorMessage])

  return <Wrapper disableAnimation={props.disableAnimation}>
    {!props.isPreProcessingPayment && props.isSuccess && props.successElement}

    {
      errorMessage && errorMessage.length > 0 &&
            <div style={{
              backgroundColor: RADOM_COLORS.ERROR,
              padding: 15,
              borderRadius: 5,
              marginBottom: 20,
              color: 'white',
              overflowWrap: 'break-word'
            }}>
              {errorMessage}
            </div>
    }

    {
      !props.isSuccess && props.checkoutData.gateway.managed && <Container disableAnimation={props.disableAnimation}>

        {
          !props.managedPaymentDetails &&
          managedSubscriptionState === undefined &&
          <Container disableAnimation={props.disableAnimation}>
            {
              props.checkoutData.inputFields && props.checkoutData.inputFields.length > 0 &&
              !props.isSuccess &&
                <div
                  style={{
                    width: '100%',
                    display: 'grid',
                    gap: 20,
                    paddingBottom: 25,
                    borderBottom: `1px solid ${RADOM_COLORS.GRAY_MED}`
                  }}>
                  {
                    localInputFields.map((c, i) => <div key={i} style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                      <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                        <InputLabel>{c.inputLabel === '' ? 'Input label' : c.inputLabel}</InputLabel>
                        {!c.isRequired && <Cookie>Optional</Cookie>}
                      </div>
                      <div>
                        {
                          ['Email', 'String'].includes(c.dataType) &&
                          <TextInput
                            disabled={props.isPreProcessingPayment}
                            ref={el => inputRefs.current[i] = el}
                            placeholder={`Enter ${c.inputLabel.toLowerCase()}`}
                            value={c.value as string}
                            required={c.isRequired}
                            onInput={(e) => {
                              const target = (e.target as HTMLInputElement)
                              let val = target.value
                              if (c.inputLabel === 'Email address') {
                                val = val.toLowerCase()
                              }
                              const newInputs = localInputFields.slice()
                              newInputs[i].value = val
                              props.onInputFieldsChange?.(newInputs)
                              setSubscriptionEmailAddress(val.toLowerCase())
                            }}
                          />
                        }
                        {
                          c.dataType === 'Number' &&
                          <TextInput
                            disabled={props.isPreProcessingPayment}
                            type="number"
                            value={c.value as number}
                            ref={el => inputRefs.current[i] = el}
                            required={c.isRequired}
                            placeholder="Enter a number"
                            onInput={e => {
                              const val = (e.target as HTMLInputElement).value
                              const newInputs = localInputFields.slice()
                              newInputs[i].value = val
                              props.onInputFieldsChange?.(newInputs)
                            }}
                          />
                        }
                      </div>
                    </div>
                    )
                  }
                </div>
            }

            <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <InputLabel>Payment method</InputLabel>
                <div style={{
                  display: 'flex',
                  gap: 5,
                  borderRadius: 5,
                  padding: '3px 6px'
                }}>
                  <span style={{ color: RADOM_COLORS.GRAY_DARKER, fontSize: 12, textTransform: 'uppercase' }}>Quote expiry</span>
                  <div style={{ display: 'flex', alignItems: 'center', fontSize: 12 }}>
                    {isLoadingPriceQuotes
                      ? <Spinner style={{ width: 10, height: 10 }} />
                      : formatSecondsToMinutesAndSecondsString(quoteSecondsRemaining)
                    }
                  </div>
                </div>
              </div>

              <div style={{ border: '1px solid', borderColor: RADOM_COLORS.GRAY_DARK, borderRadius: 5, maxHeight: '300px', overflow: 'auto' }}>
                {
                  // For invoice preview
                  props.checkoutData.gateway.managed.methods.length === 0 &&
                  <div style={{ fontSize: 14, color: RADOM_COLORS.GRAY_DARKER, margin: 20 }}>
                    No available payment methods
                  </div>
                }
                {
                  props.checkoutData.gateway.managed.methods
                    .map(method => ({
                      method: getMethod(method.network, method.token),
                      discount: method.discountPercentOff
                    }))
                    .map(({ method, discount }, i) => {
                      return <TokenSelector
                        key={i}
                        isDisabled={props.isPreProcessingPayment || isLoadingAllowance}
                        isSelected={isEqual(selectedMethod, method)}
                        onClick={() => {
                          if (props.isPreProcessingPayment) return
                          setSelectedMethod(method)
                        }}>
                        <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                          {
                            isEqual(selectedMethod, method) &&
                        <CheckmarkWrapper>
                          <Checkmark width={20} height={20} animationDelay='0' strokeWidth={5} />
                        </CheckmarkWrapper>
                          }

                          <div style={{
                            width: 30,
                            height: 30,
                            backgroundImage: `url(${method.logo})`,
                            backgroundSize: 'contain',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            position: 'relative'
                          }}>
                            {
                              method.tokenInfo && <div style={{
                                background: 'rgba(255, 255, 255, 0.95)',
                                borderRadius: '100%',
                                width: 15,
                                height: 15,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                position: 'absolute',
                                bottom: -1,
                                right: -5,
                                boxShadow: '0 0 1px'
                              }}>
                                <div style={{
                                  width: 10,
                                  height: 10,
                                  backgroundImage: `url(${method.tokenInfo.logo})`,
                                  backgroundSize: 'contain',
                                  backgroundPosition: 'center',
                                  backgroundRepeat: 'no-repeat'
                                }} />
                              </div>
                            }
                          </div>

                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display: 'flex', gap: 5, fontSize: 16, alignItems: 'center' }}>
                              {
                                discount &&
                                discount > 0 &&
                                <span style={{ textDecoration: 'line-through', opacity: 0.5 }}>
                                  {
                                    isLoadingPriceQuotes
                                      ? <Spinner />
                                      : formatNumber(
                                        convertValue(totalSum, props.currency, method.ticker, priceQuotes,
                                          props.checkoutData.chargeCustomerNetworkFee),
                                        {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 18
                                        }
                                      )
                                  }
                                </span>
                              }
                              <span>
                                {
                                  isLoadingPriceQuotes
                                    ? <Spinner />
                                    : formatNumber(
                                      convertValue(totalSum, props.currency, method.ticker, priceQuotes,
                                        props.checkoutData.chargeCustomerNetworkFee,
                                        discount),
                                      {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 18
                                      }
                                    )
                                }
                              </span>
                              {
                                discount &&
                                discount > 0 &&
                                <span style={{
                                  padding: '1px 5px',
                                  fontSize: 11,
                                  fontWeight: 600,
                                  background: 'linear-gradient(to right top, rgb(102 233 201), rgb(250 255 231 / 92%))',
                                  border: '1px solid rgb(7 210 7 / 63%)',
                                  boxShadow: 'rgb(7 210 7 / 73%) 0px 0px 3px',
                                  borderRadius: 5,
                                  textTransform: 'uppercase',
                                  color: '#4aa233'
                                }}>
                                  {discount * 100}% off
                                </span>
                              }
                            </div>
                            <div style={{ display: 'flex', alignItems: 'baseline', gap: 3 }}>
                              <span style={{ fontSize: 12, color: RADOM_COLORS.GRAY_DARKER }}>
                                {method.ticker}
                              </span>
                              <span style={{ color: RADOM_COLORS.GRAY_DARKEST }}>
                                on {method.tokenInfo?.networkName ?? method.name}
                              </span>
                            </div>
                          </div>
                        </div>
                      </TokenSelector>
                    })
                }
              </div>
            </div>
            <PrimaryButton
              isLoading={isLoadingPriceQuotes || isLoadingAllowance ||
              props.isPreProcessingPayment}
              style={{ background: props.checkoutData.customizations?.primaryButtonColor, width: '100%', margin: 'auto' }}
              onClick={() => {
                if (!selectedMethod) {
                  return
                }

                if (recurringInterval() > 0) {
                  setManagedSubscriptionState(SubscriptionCheckoutState.Select)
                  return
                }

                props.onCheckout({
                  managed: {
                    selectedMethod
                  },
                  priceQuotes
                })
              }}>
              Continue
            </PrimaryButton>
          </Container>
        }

        {
          !props.managedPaymentDetails &&
          managedSubscriptionState === SubscriptionCheckoutState.Select &&
          <Container>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
              <span>Payment method</span>
              {
                selectedMethod &&
              <div style={{
                display: 'flex',
                gap: 15,
                alignItems: 'center'
              }}>
                <div style={{
                  width: 30,
                  height: 30,
                  backgroundImage: `url(${selectedMethod.logo})`,
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  position: 'relative'
                }}>
                  {
                    selectedMethod.tokenInfo && <div style={{
                      background: 'rgba(255, 255, 255, 0.95)',
                      borderRadius: '100%',
                      width: 15,
                      height: 15,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      position: 'absolute',
                      bottom: -1,
                      right: -5,
                      boxShadow: '0 0 1px'
                    }}>
                      <div style={{
                        width: 10,
                        height: 10,
                        backgroundImage: `url(${selectedMethod.tokenInfo.logo})`,
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat'
                      }} />
                    </div>
                  }
                </div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span style={{ color: RADOM_COLORS.GRAY_DARKER }}>
                    {selectedMethod.ticker}
                  </span>
                  <span style={{ color: RADOM_COLORS.GRAY_DARKEST }}>
                    {selectedMethod.tokenInfo?.networkName ?? selectedMethod.name}
                  </span>
                </div>
              </div>
              }
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>

              <span>Select subscription type</span>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                <PrimaryButton
                  disabled={props.isPreProcessingPayment || isLoadingAllowance || !isAutopayEnabled(selectedMethod)}
                  style={{
                    background: 'none',
                    color: RADOM_COLORS.BLACK,
                    justifyContent: 'flex-start',
                    border: `1px solid ${RADOM_COLORS.GRAY_DARK}`,
                    padding: 20,
                    borderRadius: 5,
                    fontSize: 14
                  }}
                  onClick={() => setSelectedSubscriptionType(SubscriptionType.Autopay)}>
                  <div style={{ display: 'flex', alignItems: 'center', gap: 10, justifyContent: 'space-between', width: '100%' }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                      <Checkbox isRadio checked={selectedSubscriptionType === SubscriptionType.Autopay} />
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <span style={{ textAlign: 'left' }}>Subscribe with auto renew</span>
                        <span style={{ opacity: 0.5, textAlign: 'left' }}>Set an alllowance and automatically pay</span>
                      </div>
                    </div>
                  </div>
                </PrimaryButton>

                <PrimaryButton
                  disabled={props.isPreProcessingPayment || isLoadingAllowance}
                  style={{
                    background: 'none',
                    color: RADOM_COLORS.BLACK,
                    justifyContent: 'flex-start',
                    border: `1px solid ${RADOM_COLORS.GRAY_DARK}`,
                    padding: 20,
                    borderRadius: 5,
                    fontSize: 14
                  }}
                  onClick={() => setSelectedSubscriptionType(SubscriptionType.EmailInvoice)}>
                  <div style={{ display: 'flex', alignItems: 'center', gap: 10, justifyContent: 'space-between', width: '100%' }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                      <Checkbox isRadio checked={selectedSubscriptionType === SubscriptionType.EmailInvoice} />
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <span style={{ textAlign: 'left' }}>Subscribe with email invoices</span>
                        <span style={{ opacity: 0.5, textAlign: 'left' }}>Manually pay using email invoices</span>
                      </div>
                    </div>
                  </div>
                </PrimaryButton>
              </div>

              {
                selectedSubscriptionType === SubscriptionType.EmailInvoice &&
                <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                  <span>Email address</span>
                  <TextInput
                    autoFocus
                    placeholder='Enter email address'
                    value={subscriptionEmailAddress}
                    onChange={e => setSubscriptionEmailAddress(e.target.value.toLowerCase())} />
                </div>
              }

              {
                selectedSubscriptionType === SubscriptionType.Autopay &&
                <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>

                  <div style={{ display: 'flex', gap: 8, alignItems: 'center', cursor: 'pointer' }}
                    onClick={() => setReceiveEmailNotifications(!receiveEmailNotifications)}>
                    <Checkbox checked={receiveEmailNotifications} />
                    <span>Send me email notifications about my subscription</span>
                  </div>

                  {
                    receiveEmailNotifications &&
                    <TextInput
                      autoFocus
                      placeholder='Enter email address'
                      value={subscriptionEmailAddress}
                      onChange={e => setSubscriptionEmailAddress(e.target.value.toLowerCase())} />
                  }
                </div>
              }
            </div>

            {
              selectedSubscriptionType === SubscriptionType.Autopay && address &&
              <ConnectedWalletContainer>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    border: `1px solid ${RADOM_COLORS.GRAY_DARK}`,
                    borderRadius: 5
                  }}>
                  <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: 15,
                    backgroundColor: RADOM_COLORS.GRAY_MED
                  }}>
                    <span style={{ fontSize: 14 }}>Connected wallet</span>
                    <SecondaryButton
                      style={{
                        border: 0,
                        padding: 0,
                        color: RADOM_COLORS.BLACK,
                        fontSize: 10,
                        textTransform: 'uppercase'
                      }}
                      onClick={() => disconnect()}>
                        Disconnect
                    </SecondaryButton>
                  </div>
                  <span style={{ padding: 15 }}>{address}</span>
                </div>
              </ConnectedWalletContainer>
            }

            <PrimaryButton
              isLoading={
                isLoadingPriceQuotes ||
                isSettingAllowance ||
                props.isPreProcessingPayment ||
                isLoadingAllowance
              }
              disabled={
                selectedSubscriptionType === undefined ||
                (selectedSubscriptionType === SubscriptionType.EmailInvoice && !subscriptionEmailAddress)
              }
              style={{ background: props.checkoutData.customizations?.primaryButtonColor, width: '100%', margin: 'auto' }}
              onClick={async () => {
                if (selectedSubscriptionType === SubscriptionType.Autopay && !isConnected) {
                  return await open()
                }

                if (!selectedMethod || selectedSubscriptionType === undefined) {
                  return
                }

                props.onCheckout({
                  managed: {
                    selectedMethod
                  },
                  priceQuotes,
                  subscriptionOptions: {
                    autopay: selectedSubscriptionType === SubscriptionType.Autopay
                      ? {
                        subscriptionType: selectedSubscriptionType,
                        address: address as any
                      }
                      : undefined,
                    emailInvoice: selectedSubscriptionType === SubscriptionType.EmailInvoice
                      ? {
                        subscriptionType: selectedSubscriptionType,
                        emailAddress: subscriptionEmailAddress.toLowerCase()
                      }
                      : undefined
                  }
                })
              }}>
              {
                selectedSubscriptionType === SubscriptionType.Autopay && !isConnected
                  ? 'Connect wallet'
                  : 'Continue'
              }
            </PrimaryButton>

            <SecondaryButton
              disabled={isLoadingPriceQuotes || isSettingAllowance ||
                isLoadingAllowance || props.isPreProcessingPayment}
              style={{ border: 0, padding: 5, color: RADOM_COLORS.GRAY_DARKER }}
              onClick={() => {
                console.log('resetting error message')
                setManagedSubscriptionState(undefined)
                setErrorMessage('')
              }}>
                    Change payment method
            </SecondaryButton>
          </Container>
        }

        {
          !props.managedPaymentDetails &&
          (managedSubscriptionState === SubscriptionCheckoutState.Allowance ||
          managedSubscriptionState === SubscriptionCheckoutState.Confirm) &&
          selectedMethod &&
          <Container style={{ gap: 30 }}>
            <div style={{
              display: 'flex',
              userSelect: 'none',
              alignItems: 'center',
              gap: 10,
              padding: 15,
              borderRadius: 5,
              border: `1px solid ${RADOM_COLORS.GRAY_MED}`
            }}>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                gap: 5,
                flexShrink: 0,
                padding: '0 20px'
              }}>
                <span style={{
                  display: 'flex',
                  borderRadius: '100%',
                  width: 20,
                  height: 20,
                  backgroundColor: managedSubscriptionState === SubscriptionCheckoutState.Confirm
                    ? RADOM_COLORS.SUCCESS_LIGHT
                    : RADOM_COLORS.NICE_GREEN,
                  fontSize: 12,
                  fontWeight: 500,
                  color: 'white',
                  alignItems: 'center',
                  justifyContent: 'center',
                  transition: '0.2s ease all'
                }}>1</span>
                <span style={{
                  fontSize: 16,
                  fontWeight: managedSubscriptionState === SubscriptionCheckoutState.Confirm ? 300 : 400,
                  color: managedSubscriptionState === SubscriptionCheckoutState.Confirm
                    ? RADOM_COLORS.GRAY_DARKEST
                    : RADOM_COLORS.BLACK,
                  transition: '0.2s ease all'
                }}>Set allowance</span>
              </div>

              <div style={{
                width: '100%',
                backgroundColor: RADOM_COLORS.GRAY_DARK,
                height: 2,
                borderRadius: 2,
                flexGrow: 1,
                transition: '0.2s ease all'
              }} />
              <div style={{
                display: 'flex',
                alignItems: 'center',
                gap: 5,
                flexShrink: 0,
                padding: '0 20px'
              }}>
                <span style={{
                  display: 'flex',
                  borderRadius: '100%',
                  width: 20,
                  height: 20,
                  backgroundColor: managedSubscriptionState === SubscriptionCheckoutState.Confirm
                    ? RADOM_COLORS.NICE_GREEN
                    : RADOM_COLORS.GRAY_DARKER,
                  fontSize: 12,
                  fontWeight: 500,
                  color: 'white',
                  alignItems: 'center',
                  justifyContent: 'center',
                  transition: '0.2s ease all'
                }}>2</span>
                <span style={{
                  fontSize: 16,
                  fontWeight: managedSubscriptionState === SubscriptionCheckoutState.Confirm ? 400 : 300
                }}>Subscribe</span>

              </div>
            </div>

            {
              managedSubscriptionState === SubscriptionCheckoutState.Allowance && <>
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 10,
                  color: RADOM_COLORS.BLACK,
                  borderRadius: 5
                }}>
                  <span>Set an allowance to enable automatically recurring payments for your subscription.</span>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                  <PrimaryButton
                    isLoading={isLoadingPriceQuotes || isSettingAllowance}
                    style={{
                      background: props.checkoutData.customizations?.primaryButtonColor,
                      width: '100%',
                      margin: 'auto'
                    }}
                    onClick={() => {
                      setErrorMessage('')
                      setManagedSubscriptionAllowance()
                    }}>
                    Set allowance
                  </PrimaryButton>

                  {
                    allowSkipAllowance &&
                    <SecondaryButton
                      disabled={isLoadingPriceQuotes || isSettingAllowance}
                      style={{
                        padding: 5,
                        color: RADOM_COLORS.GRAY_DARKER,
                        borderColor: RADOM_COLORS.GRAY_DARK
                      }}
                      onClick={() => setManagedSubscriptionState(SubscriptionCheckoutState.Confirm) }>
                      Skip
                    </SecondaryButton>
                  }

                  <SecondaryButton
                    disabled={isLoadingPriceQuotes || isSettingAllowance}
                    style={{ border: 0, padding: 5, color: RADOM_COLORS.GRAY_DARKER }}
                    onClick={() => { setManagedSubscriptionState(undefined); setErrorMessage('') }}>
                    Change payment method
                  </SecondaryButton>
                </div>
              </>
            }

            {
              managedSubscriptionState === SubscriptionCheckoutState.Confirm &&
              <Container>
                <div style={{ boxShadow: `0 0 3px ${RADOM_COLORS.GRAY_DARK}`, borderRadius: 5 }}>
                  <div style={{
                    backgroundColor: RADOM_COLORS.GRAY_MED,
                    border: `1px solid ${RADOM_COLORS.GRAY_DARK}`,
                    borderTopLeftRadius: 5,
                    borderTopRightRadius: 5,
                    padding: 10
                  }}>Initial payment</div>
                  <div style={{
                    display: 'flex',
                    gap: 15,
                    alignItems: 'center',
                    border: `1px solid ${RADOM_COLORS.GRAY_DARK}`,
                    padding: 15,
                    borderTop: 0,
                    borderBottomLeftRadius: 5,
                    borderBottomRightRadius: 5
                  }}>
                    <div style={{
                      width: 30,
                      height: 30,
                      backgroundImage: `url(${selectedMethod.logo})`,
                      backgroundSize: 'contain',
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                      position: 'relative'
                    }}>
                      {
                        selectedMethod.tokenInfo && <div style={{
                          background: 'rgba(255, 255, 255, 0.95)',
                          borderRadius: '100%',
                          width: 15,
                          height: 15,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          position: 'absolute',
                          bottom: -1,
                          right: -5,
                          boxShadow: '0 0 1px'
                        }}>
                          <div style={{
                            width: 10,
                            height: 10,
                            backgroundImage: `url(${selectedMethod.tokenInfo.logo})`,
                            backgroundSize: 'contain',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat'
                          }} />
                        </div>
                      }
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>

                        <span style={{ fontSize: 16 }}>
                          {
                            isLoadingPriceQuotes
                              ? <Spinner />
                              : formatNumber(
                                convertValue(totalSum, props.currency, selectedMethod.ticker, priceQuotes,
                                  false),
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 18
                                }
                              )
                          }
                        </span>

                        {
                          props.checkoutData.chargeCustomerNetworkFee &&
                            <>
                              <span>+</span>

                              <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 4,
                                backgroundColor: RADOM_COLORS.GRAY_MED,
                                borderRadius: 5,
                                padding: '2px 6px',
                                color: RADOM_COLORS.BLACK
                              }}>
                                <GasPump style={{ height: 10 }} fill={RADOM_COLORS.BLACK} />
                                <span>{gasCost}</span>
                              </div>
                            </>
                        }

                      </div>
                      <div style={{ display: 'flex', alignItems: 'baseline', gap: 3 }}>
                        <span style={{ fontSize: 12, color: RADOM_COLORS.GRAY_DARKER }}>
                          {selectedMethod.ticker}
                        </span>
                        <span style={{ color: RADOM_COLORS.GRAY_DARKEST }}>
                          on {selectedMethod.tokenInfo?.networkName ?? selectedMethod.name}
                        </span>
                      </div>
                    </div>
                  </div>

                </div>

                <div style={{ boxShadow: `0 0 3px ${RADOM_COLORS.GRAY_DARK}`, borderRadius: 5 }}>
                  <div style={{
                    border: `1px solid ${RADOM_COLORS.GRAY_DARK}`,
                    borderBottom: 0,
                    borderTopLeftRadius: 5,
                    borderTopRightRadius: 5,
                    padding: 10,
                    color: RADOM_COLORS.GRAY_DARKEST,
                    backgroundColor: RADOM_COLORS.GRAY_LIGHTEST
                  }}>Recurring payments</div>
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 10,
                    padding: '10px 20px',
                    color: RADOM_COLORS.GRAY_DARKEST,
                    borderTop: 0,
                    border: `1px solid ${RADOM_COLORS.GRAY_DARK}`,
                    borderBottomLeftRadius: 5,
                    borderBottomRightRadius: 5
                  }}>
                    <div style={{
                      width: 20,
                      height: 20,
                      backgroundImage: `url(${selectedMethod.logo})`,
                      backgroundSize: 'contain',
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                      position: 'relative'
                    }}>
                      {
                        selectedMethod.tokenInfo && <div style={{
                          background: 'rgba(255, 255, 255, 0.95)',
                          borderRadius: '100%',
                          width: 12,
                          height: 12,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          position: 'absolute',
                          bottom: -1,
                          right: -5,
                          boxShadow: '0 0 1px'
                        }}>
                          <div style={{
                            width: 8,
                            height: 8,
                            backgroundImage: `url(${selectedMethod.tokenInfo.logo})`,
                            backgroundSize: 'contain',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat'
                          }} />
                        </div>
                      }
                    </div>
                    <div style={{ display: 'flex', gap: 3 }}>
                      {formatNumber(
                        convertValue(recurringSum, props.currency, selectedMethod.ticker, priceQuotes,
                          false),
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        }
                      )} {
                        props.checkoutData.chargeCustomerNetworkFee &&
                           `+ gas fee per ${formatSubscriptionDuration(recurringInterval() * 60 * 60 * 24).toLowerCase()}`
                      }
                      <span>starting</span>
                      {nextBillingAt()}
                    </div>
                  </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                  <PrimaryButton
                    isLoading={
                      isLoadingPriceQuotes || props.isPreProcessingPayment || isSigningMessage
                    }
                    style={{
                      background: props.checkoutData.customizations?.primaryButtonColor,
                      width: '100%',
                      margin: 'auto'
                    }}
                    onClick={async () => {
                      if (!props.tokenDelegatePaymentDetails || !address) {
                        return
                      }

                      setErrorMessage('')
                      setIsSigningMessage(true)

                      try {
                        const chain = NetworkToChainMap[props.tokenDelegatePaymentDetails.network] as any
                        const provider: any = await connector?.getProvider()

                        const signer = createWalletClient({
                          chain,
                          transport: custom(provider)
                        })

                        const signature = await signer?.signMessage({
                          account: address,
                          message: props.tokenDelegatePaymentDetails.messageToSign
                        })

                        props.onCheckout({
                          completeTokenDelegate: {
                            paymentId: props.tokenDelegatePaymentDetails.paymentId,
                            signature
                          },
                          priceQuotes
                        })
                      } catch (err) {
                        setErrorMessage(err.toString())
                      }

                      setIsSigningMessage(false)
                    }}>
                    Subscribe
                  </PrimaryButton>

                  <SecondaryButton
                    style={{ border: 0, padding: 5, color: RADOM_COLORS.GRAY_DARKER }}
                    disabled={isLoadingPriceQuotes || props.isPreProcessingPayment || isSigningMessage}
                    onClick={() => { setManagedSubscriptionState(undefined); setErrorMessage('') }}>
                    Change payment method
                  </SecondaryButton>
                </div>

              </Container>
            }

          </Container>
        }

        {
          props.managedPaymentDetails &&
          <Container style={{
            overflowWrap: 'anywhere',
            ...(props.posMode && {
              alignItems: 'center',
              gap: 60,
              overflowWrap: 'normal'
            })
          }}>
            {
              props.posMode &&
              <WaveContainer>
                <Wave />
                <Wave2 />
                <Wave3 />
              </WaveContainer>
            }
            <Styles />
            {
              props.posMode &&
              <div style={{ display: 'flex', flexDirection: 'column', fontSize: 12, gap: 3, alignItems: 'center' }}>
                <span style={{ opacity: 0.5, textTransform: 'uppercase', textAlign: props.posMode ? 'center' : undefined }}>Time remaining</span>
                <span style={{ fontSize: 16, fontWeight: 100, textAlign: 'center' }}>
                  {
                    formatSecondsToMinutesAndSecondsString(
                      (new Date(
                        props.paymentStatus?.expiresAt ??
                        props.managedPaymentDetails.expiresAt
                      ).getTime() - new Date().getTime()) / 1000
                    )
                  }
                </span>
              </div>
            }
            {
              props.paymentStatus?.status !== 'expired' &&
                props.checkoutData.customizations?.displayQrCode !== false &&
              <QRCodeWrapper>
                <QRCodeWrapperInner>
                  <img src={managedPaymentQrCode} style={{ width: 200, height: 200 }} />
                </QRCodeWrapperInner>
              </QRCodeWrapper>
            }

            <div style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 20,
              ...(props.posMode && { gap: 40 })
            }}>

              <div style={{
                display: 'flex',
                flexDirection: 'column',
                fontSize: 12,
                gap: 3,
                ...(props.posMode && { alignItems: 'center' })
              }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                  <span style={{ opacity: 0.5, textTransform: 'uppercase' }}>Payment address</span>
                  {
                    props.paymentStatus?.status !== 'expired' &&
                    <CopyButton onClick={async () =>
                      await navigator.clipboard.writeText(props.managedPaymentDetails?.paymentAddress ?? '')} />
                  }
                </div>
                <span style={{ fontSize: 16, display: 'flex', alignItems: 'center', gap: 2, fontWeight: 100 }}>
                  {props.paymentStatus?.status === 'expired'
                    ? 'Expired'
                    : props.managedPaymentDetails.paymentAddress
                  }
                </span>
              </div>

              <div style={{ display: 'flex', gap: '20px 40px', flexWrap: 'wrap' }}>
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  fontSize: 12,
                  gap: 3,
                  flexGrow: 1,
                  ...(props.posMode && { alignItems: 'center' })
                }}>
                  <span style={{ opacity: 0.5, textTransform: 'uppercase' }}>Network</span>
                  <div style={{ fontSize: 16, display: 'flex', alignItems: 'center', gap: 5, fontWeight: 300 }}>
                    <img
                      src={
                        getMethod(
                          props.managedPaymentDetails.network,
                          props.managedPaymentDetails.token
                        )?.tokenInfo?.logo ??
                        getMethod(props.managedPaymentDetails.network, props.managedPaymentDetails.token).logo
                      }
                      style={{ height: 20, width: 'auto' }}
                    />
                    <span style={{ fontWeight: 300 }}>
                      {
                        getMethod(
                          props.managedPaymentDetails.network,
                          props.managedPaymentDetails.token
                        ).tokenInfo?.networkName ??
                      getMethod(props.managedPaymentDetails.network, props.managedPaymentDetails.token).name
                      }
                    </span>
                  </div>
                </div>

                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  fontSize: 12,
                  gap: 3,
                  flexGrow: 1,
                  ...(props.posMode && { alignItems: 'center' })
                }}>
                  <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                    <span style={{ opacity: 0.5, textTransform: 'uppercase' }}>Amount due</span>
                    {
                      props.paymentStatus?.status !== 'expired' &&
                    <CopyButton onClick={async () =>
                      await navigator.clipboard.writeText(formatNumber(
                        props.managedPaymentDetails?.amount as any, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 18
                        }))} />
                    }
                  </div>

                  <div style={{ fontSize: 16, display: 'flex', alignItems: 'center', gap: 5, fontWeight: 300 }}>
                    <img
                      src={getMethod(props.managedPaymentDetails.network, props.managedPaymentDetails.token).logo}
                      style={{ height: 20, width: 'auto' }}
                    />
                    {
                      formatNumber(props.managedPaymentDetails.amount as any, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 18
                      })
                    }
                    <span style={{ opacity: 0.5, fontWeight: 100 }}>
                      {getMethod(props.managedPaymentDetails.network, props.managedPaymentDetails.token).ticker}
                    </span>
                  </div>
                </div>
              </div>

              {
                !props.posMode &&
                <div style={{ display: 'flex', flexDirection: 'column', fontSize: 12, gap: 3 }}>
                  <span style={{ opacity: 0.5, textTransform: 'uppercase' }}>Time remaining</span>
                  <span style={{ fontSize: 16, fontWeight: 100 }}>
                    {
                      props.paymentStatus?.status === 'expired'
                        ? 'Expired'
                        : formatSecondsToMinutesAndSecondsString(
                          (
                            new Date(
                              props.paymentStatus?.expiresAt ??
                              props.managedPaymentDetails.expiresAt
                            ).getTime() -
                             new Date().getTime()) /
                            1000
                        )
                    }
                  </span>
                </div>
              }

              {
                props.paymentStatus && props.paymentStatus.transactions.length > 0 &&
                <div style={{ borderRadius: 15, border: `1px solid ${RADOM_COLORS.GRAY_MED}`, overflow: 'hidden' }}>
                  <div style={{ padding: 15, backgroundColor: 'rgb(255 223 230)' }}>Underpaid payment</div>
                  <div style={{ padding: 20, display: 'flex', flexDirection: 'column', gap: 20 }}>
                    <div>We have detected a payment transaction,
                      but there is still an amount remaining to be paid.</div>
                    <div style={{ width: '100%', height: 1, backgroundColor: RADOM_COLORS.GRAY_DARK }} />
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                      <span style={{ opacity: 0.5, textTransform: 'uppercase', fontSize: 12 }}>Amount remaining</span>
                      <span>

                        <div style={{ fontSize: 16, display: 'flex', alignItems: 'center', gap: 5, fontWeight: 300 }}>
                          <img
                            src={getMethod(props.managedPaymentDetails.network, props.managedPaymentDetails.token).logo}
                            style={{ height: 20, width: 'auto' }}
                          />
                          {
                            formatNumber(props.paymentStatus.amountRemaining as any, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 18
                            })
                          }
                          <span style={{ opacity: 0.5, fontWeight: 100 }}>
                            {getMethod(props.managedPaymentDetails.network, props.managedPaymentDetails.token).ticker}
                          </span>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              }

              {
                props.paymentStatus &&
                props.paymentStatus.pendingTransactions.length > 0 &&
                props.paymentStatus.transactions.length <= 0 &&
                <PaymentDetectedContainer>
                  <div style={{ padding: 15, backgroundColor: RADOM_COLORS.GRAY_MED }}>Payment detected</div>
                  <div style={{ padding: 20, display: 'flex', flexDirection: 'column', gap: 20 }}>
                    <div>We have detected your payment transaction pending to be confirmed.</div>
                    <div style={{ width: '100%', height: 1, backgroundColor: RADOM_COLORS.GRAY_DARK }} />
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                      <span style={{ opacity: 0.5, textTransform: 'uppercase', fontSize: 12 }}>Amount paid</span>
                      <span>

                        <div style={{ fontSize: 16, display: 'flex', alignItems: 'center', gap: 5, fontWeight: 300 }}>
                          <img
                            src={getMethod(props.managedPaymentDetails.network, props.managedPaymentDetails.token).logo}
                            style={{ height: 20, width: 'auto' }}
                          />
                          {
                            formatNumber(
                              props.paymentStatus.pendingTransactions.reduce((s, t) => s + Number(t.amount), 0),
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 18
                              })
                          }
                          <span style={{ opacity: 0.5, fontWeight: 100 }}>
                            {getMethod(props.managedPaymentDetails.network, props.managedPaymentDetails.token).ticker}
                          </span>
                        </div>
                      </span>
                    </div>
                  </div>
                </PaymentDetectedContainer>
              }

              {
                !props.posMode && props.paymentStatus?.status !== 'expired' &&
                props.checkoutData.organizationId !== 'f58cf6b3-b87d-4893-b859-cddc85ac0abf' &&
                <div style={{
                  width: '100%',
                  borderTop: `1px solid ${RADOM_COLORS.GRAY_DARK}`,
                  paddingTop: 20,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 20
                }}>

                  {
                    !selectedMethod?.isSolana && <>
                      {
                        isConnected &&
                        <ConnectedWalletContainer>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              border: `1px solid ${RADOM_COLORS.GRAY_DARK}`,
                              borderRadius: 5
                            }}>
                            <div style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              padding: 15,
                              backgroundColor: RADOM_COLORS.GRAY_MED
                            }}>
                              <span style={{ fontSize: 14 }}>Connected wallet</span>
                              <SecondaryButton
                                style={{
                                  border: 0,
                                  padding: 0,
                                  color: RADOM_COLORS.BLACK,
                                  fontSize: 10,
                                  textTransform: 'uppercase'
                                }}
                                onClick={() => disconnect()}>
                              Disconnect
                              </SecondaryButton>
                            </div>
                            <span style={{ padding: 15 }}>{address}</span>
                          </div>
                        </ConnectedWalletContainer>
                      }
                      <PrimaryButton
                        isLoading={isManagedEVMTxLoading}
                        onClick={onClickOpenWallet}
                        style={{
                          width: '100%',
                          background: props.checkoutData.customizations?.primaryButtonColor
                        }}>
                        {
                          isConnected ? 'Open wallet' : 'Connect wallet'
                        }
                      </PrimaryButton>
                    </>
                  }

                  {
                    selectedMethod?.isSolana &&
                    <SolanaWallet
                      managedPaymentDetails={props.managedPaymentDetails}
                      isLoading={isManagedEVMTxLoading}
                      setIsLoading={setIsManagedEVMTxLoading}
                      primaryButtonColor={props.checkoutData.customizations?.primaryButtonColor} />
                  }
                </div>
              }

              {
                !props.posMode && props.paymentStatus?.status === 'expired' &&
                <div style={{
                  width: '100%',
                  borderTop: `1px solid ${RADOM_COLORS.GRAY_DARK}`,
                  paddingTop: 20
                }}>
                  <PrimaryButton
                    onClick={() => window.location.reload()}
                    style={{
                      width: '100%',
                      background: props.checkoutData.customizations?.primaryButtonColor
                    }}>
                    Reload
                  </PrimaryButton>
                </div>
              }
            </div>

          </Container>
        }

      </Container>
    }

  </Wrapper>
}

export default PayWidget
