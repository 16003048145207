import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { RADOM_COLORS } from '../util/Constants'
import { PrimaryButton, SecondaryButton } from './Button'
import { IManagedPaymentMethodDetails } from '../api/Radom'
import { getMethod } from '../util/Managed'
import { parseUnits } from 'viem'
import { ConnectionProvider, WalletProvider, useConnection, useWallet } from '@solana/wallet-adapter-react'
import { PublicKey, SystemProgram, Transaction } from '@solana/web3.js'
import * as TokenProgram from '@solana/spl-token'
import Modal from './Modal'
import { shortAddress } from '../util/Util'
import { isMobile } from 'react-device-detect'
import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui'

import '@solana/wallet-adapter-react-ui/styles.css'

const ConnectedWalletContainer = styled.div`
  box-shadow: 0 0 3px ${RADOM_COLORS.GRAY_DARK};
  border-radius: 5px;
`

function endpointUrl(cluster: 'devnet' | 'mainnet'): string {
  if (cluster === 'devnet') {
    return 'https://api.devnet.solana.com'
  }

  return 'https://api.radom.com/solana'
}

function maxBigInt(a: bigint, b: bigint): bigint {
  return a > b ? a : b
}

interface IProps {
  isLoading: any
  setIsLoading: any
  managedPaymentDetails: IManagedPaymentMethodDetails
  primaryButtonColor?: string
  presaleStyling?: {
    buttonTextColor: string
    darkTextColor: string
    titleTextColor: string
  }
}

const SolanaWalletInner = (props: IProps): React.ReactElement => {
  const {
    connect, connected, disconnect: solanaDisconnect, sendTransaction,
    publicKey, select, wallet, wallets
  } = useWallet()
  const { connection } = useConnection()

  useEffect(() => {
    if (wallet && !connected && !isMobile) {
      connect()
    }
  }, [])

  const [isSolanaWalletModalVisible, setIsSolanaWalletModalVisible] = useState(false)

  useEffect(() => {
    if (wallet && !isMobile) {
      connect()
    }
  }, [wallet])

  const { isLoading, setIsLoading, managedPaymentDetails } = props

  const onClick = async (): Promise<void> => {
    if (!publicKey) {
      return
    }

    setIsLoading(true)
    try {
      let transaction: Transaction

      const {
        value: { blockhash, lastValidBlockHeight },
        context: { slot: minContextSlot }
      } = await connection.getLatestBlockhashAndContext()

      if (managedPaymentDetails.token) {
        const tokenAddress = await TokenProgram.getAssociatedTokenAddress(
          new PublicKey(managedPaymentDetails.token),
          new PublicKey(managedPaymentDetails.paymentAddress)
        )
        const fromTokenAddress = await TokenProgram.getAssociatedTokenAddress(
          new PublicKey(managedPaymentDetails.token),
          publicKey
        )

        const method = getMethod(managedPaymentDetails.network, managedPaymentDetails.token)

        transaction = new Transaction({
          blockhash,
          lastValidBlockHeight,
          feePayer: publicKey
        })
          .add(
            TokenProgram.createAssociatedTokenAccountIdempotentInstruction(
              publicKey,
              new PublicKey(tokenAddress),
              new PublicKey(managedPaymentDetails.paymentAddress),
              new PublicKey(managedPaymentDetails.token)
            )
          )
          .add(
            TokenProgram.createTransferInstruction(
              fromTokenAddress,
              tokenAddress,
              publicKey,
              parseUnits(managedPaymentDetails.amount, method.tokenInfo?.decimals ?? 9)
            )

          )
      } else {
        const minimumAmount = BigInt(await connection.getMinimumBalanceForRentExemption(0))

        transaction = new Transaction({
          blockhash,
          lastValidBlockHeight,
          feePayer: publicKey
        }).add(
          SystemProgram.transfer({
            fromPubkey: publicKey,
            toPubkey: new PublicKey(managedPaymentDetails.paymentAddress),
            lamports: maxBigInt(parseUnits(managedPaymentDetails.amount, 9), minimumAmount)
          })
        )
      }

      const signature = await sendTransaction(transaction, connection, { preflightCommitment: 'processed', minContextSlot })

      await connection.confirmTransaction({ blockhash, lastValidBlockHeight, signature })
    } catch (err) {
      console.error('Failed', err)
      setIsLoading(false)
    }
  }

  return <>
    {
      wallet?.adapter.publicKey &&
      <>
        {
          props.presaleStyling &&
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: 10
        }}>

          <div style={{
            color: props.presaleStyling.darkTextColor,
            display: 'flex',
            flexDirection: 'column',
            gap: 5
          }}>

            <span style={{ textTransform: 'uppercase', fontSize: 10 }}>Connected wallet</span>
            <span>{shortAddress(wallet.adapter.publicKey.toString(), 10)}</span>
          </div>

          <SecondaryButton
            disabled={isLoading}
            onClick={async () => await solanaDisconnect()}
            style={{
              padding: 0,
              border: 0,
              color: props.presaleStyling.titleTextColor,
              textTransform: 'uppercase',
              fontSize: 10
            }}>
            Disconnect
          </SecondaryButton>
        </div>
        }
        {
          !props.presaleStyling &&
      <ConnectedWalletContainer>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            border: `1px solid ${RADOM_COLORS.GRAY_DARK}`,
            borderRadius: 5
          }}>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: 15,
            backgroundColor: RADOM_COLORS.GRAY_MED
          }}>
            <span style={{ fontSize: 14 }}>Connected wallet</span>
            <SecondaryButton
              style={{
                border: 0,
                padding: 0,
                color: RADOM_COLORS.BLACK,
                fontSize: 10,
                textTransform: 'uppercase'
              }}
              onClick={async () => await solanaDisconnect()}>
              Disconnect
            </SecondaryButton>
          </div>
          <span style={{ padding: 15 }}>{wallet.adapter.publicKey.toString()}</span>
        </div>
      </ConnectedWalletContainer>
        }
      </>
    }

    {
      wallet?.adapter.publicKey
        ? <PrimaryButton
          isLoading={isLoading}
          onClick={() => {
            onClick()
          }}
          style={{
            width: '100%',
            background: props.primaryButtonColor,
            color: props.presaleStyling?.buttonTextColor
          }}>
        Open wallet
        </PrimaryButton>
        : <WalletMultiButton style={{
          width: '100%',
          backgroundColor: 'black',
          background: props.primaryButtonColor,
          color: props.presaleStyling?.buttonTextColor,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          fontWeight: 400,
          borderRadius: 30
        }} />
    }

    <Modal
      onClose={() => setIsSolanaWalletModalVisible(!isSolanaWalletModalVisible)}
      visible={isSolanaWalletModalVisible}
      innerStyles={{ width: 350 }}>

      <div style={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        padding: 20,
        gap: 10,
        fontSize: 14
      }}>
        <div style={{ marginBottom: 10 }}>
          <span style={{ fontSize: 16, fontWeight: 500 }}>Select wallet</span>
        </div>

        {
          wallets.length <= 0 &&
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '30px 0',
            gap: 3
          }}>
            <span style={{ fontSize: 14 }}>No wallet found.</span>
            <span style={{ opacity: 0.5 }}>Please install a Solana compatible wallet.</span>
            <span style={{ opacity: 0.5 }}>Please open the app within Phantom or Solflare.</span>
          </div>
        }

        {
          wallets.map((w, i) =>
            <PrimaryButton
              key={i}
              onClick={async () => {
                if (isMobile) {
                  await connect()
                  setIsSolanaWalletModalVisible(false)
                } else {
                  select(w.adapter.name)
                  setIsSolanaWalletModalVisible(false)
                }
              }}
              style={{
                backgroundColor: RADOM_COLORS.GRAY_MED,
                color: RADOM_COLORS.BLACK
              }}>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                gap: 15,
                justifyContent: 'flex-start',
                width: '100%'
              }}>
                <div style={{
                  width: 20,
                  height: 20,
                  backgroundImage: `url(${w.adapter.icon})`,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center center'
                }} />
                <span>{w.adapter.name}</span>
              </div>
            </PrimaryButton>
          )
        }

      </div>

    </Modal>
  </>
}

const SolanaWallet = (props: IProps): React.ReactElement => {
  const endpoint = endpointUrl(props.managedPaymentDetails.network === 'Solana' ? 'mainnet' : 'devnet')

  return <ConnectionProvider endpoint={endpoint}>
    <WalletProvider wallets={[]} autoConnect>
      <WalletModalProvider>
        <SolanaWalletInner {...props} />
      </WalletModalProvider>
    </WalletProvider>
  </ConnectionProvider>
}

export default SolanaWallet
