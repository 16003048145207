import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import Radom, { CheckoutSession, IDiscountCode, IManagedPaymentMethodDetails, IManagedPaymentStatus, IManagedTokenDelegatePaymentMethodDetails, PaymentMethod, ProductWithQuantity, Profile } from '../api/Radom'
import { CheckoutData } from '../components/Checkout'
import PayWidget, { CheckoutParams } from '../components/PayWidget'
import Spinner from '../components/Spinner'
import Arrow from '../icons/Arrow'
import { getTotalSum } from '../util/Products'
import { RADOM_COLORS, formatSubscriptionDuration } from '../util/Constants'
import { CheckmarkWrapper } from '../components/Receipt'
import Checkmark from '../icons/Checkmark'
import { pickTextColorBasedOnBgColor, shortAddress } from '../util/Util'
import RadomLogo from '../icons/Logo2'
import { IconButton, SecondaryButton } from '../components/Button'
import { formatCurrency } from '../util/Currencies'
import { TextInput } from '../components/Input'
import Close from '../icons/Close'

const Container = styled.div`
  width: 100vw;
  height: 100%;
  height: 100vh;
  background-color: white;
  font-size: 14px;

  @media (max-width: 800px) {
    min-height: 100vh;
    height: auto;
  }
`

const LeftSection = styled.div<{ leftPanelColor: string, slantedEdge?: boolean }>`
  color: ${({ leftPanelColor }) => pickTextColorBasedOnBgColor(leftPanelColor, 'white', 'black')};
  background: ${({ leftPanelColor }) => leftPanelColor};
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 10px;
  min-width: min-content;
  width: 45%;
  font-size: 24px;
  ${({ slantedEdge }) => slantedEdge && 'clip-path: polygon(0 0, 100% 0, 90% 100%, 0% 100%);'}
  ${({ slantedEdge }) => slantedEdge && 'padding-right: 70px;'}

  @media (max-width: 800px) {
    width: 100%;
    clip-path: none;
    padding-right: 0px;
  }
`

const LeftSectionInner = styled.div<{ isSumPurchase?: boolean }>`
  padding: 40px 80px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: ${({ isSumPurchase }) => isSumPurchase ? 'center' : 'start'};
  align-items: ${({ isSumPurchase }) => isSumPurchase ? 'initial' : 'start'};
  width: ${({ isSumPurchase }) => isSumPurchase ? 'initial' : '100%'};
  gap: 40px;

  @media (max-width: 800px) {
    width: 100%;
    padding: 40px;
  }
`

const RightSection = styled.div`
  flex-grow: 1;
  @media (max-width: 800px) {
    padding: 40px;
    width: 100%;
    height: min-content;
    justify-content: initial;
  }
`

// const QuantityInput = styled.input`
//   width: 50px;
//   margin-left: 8px;
//   background-color: transparent;
//   color: inherit;
//   border: none;
//   border-bottom: 1px solid ${RADOM_COLORS.GRAY_DARKER};
//   outline: none;
//   text-align: center;
//   transition: border-bottom 0.2s cubic-bezier(0.215, 0.610, 0.355, 1);

//   :focus {
//     border-bottom: 1px solid ${RADOM_COLORS.ORANGE_MED};
//   }
// `

const HostedCheckout = (): React.ReactElement => {
  const { checkoutId } = useParams()
  const [searchParams] = useSearchParams()

  const posMode = useMemo(() => searchParams.get('posMode'), [])

  const [isLoading, setIsLoading] = useState(true)
  const [isPreProcessingPayment, setIsPreProcessingPayment] = useState(false)

  const [isExpired, setIsExpired] = useState(false)
  const [isCancelled, setIsCancelled] = useState(false)
  const [isComplete, setIsComplete] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [successElement, setSuccessElement] = useState<ReactElement>(<></>)
  const [errorMessage, setErrorMessage] = useState('')
  const [isSumPurchase, setIsSumPurchase] = useState(false)
  const [totalSum, setTotalSum] = useState(0)
  const [subscriptionSum, setSubscriptionSum] = useState(0)
  const [currency, setCurrency] = useState('USD')

  const [checkoutData, setCheckoutData] = useState<CheckoutData>()
  const [sellerProfile, setSellerProfile] = useState<Profile>()
  const [lines, setLines] = useState<ProductWithQuantity[]>([])
  const [textColor, setTextColor] = useState('red')

  const [managedPaymentDetails, setManagedPaymentDetails] = useState<IManagedPaymentMethodDetails>()
  const [
    tokenDelegatePaymentDetails,
    setTokenDelegatePaymentDetails
  ] = useState<IManagedTokenDelegatePaymentMethodDetails>()

  const [showDiscountCodeInput, setShowDiscountCodeInput] = useState(false)
  const [discountCode, setDiscountCode] = useState('')
  const [discountResult, setDiscountResult] = useState({ success: false, element: <></> })
  const [discount, setDiscount] = useState<IDiscountCode>()
  const [discountLocked, setDiscountLocked] = useState(false)

  const [paymentStatus, setPaymentStatus] = useState<IManagedPaymentStatus>()

  const [customizations, setCustomizations] = useState({
    leftPanelColor: RADOM_COLORS.GRAY_MED,
    primaryButtonColor: RADOM_COLORS.ORANGE,
    slantedEdge: false,
    allowDiscountCodes: false,
    displayQrCode: true
  })

  const getCheckoutData = async (): Promise<void> => {
    if (!checkoutId) {
      return
    }

    const processCheckoutSession = (checkoutSession: CheckoutSession): CheckoutSession => ({
      ...checkoutSession,
      successUrl: checkoutSession.successUrl.replace('{CHECKOUT_SESSION_ID}', checkoutSession.id),
      cancelUrl: checkoutSession.cancelUrl?.replace('{CHECKOUT_SESSION_ID}', checkoutSession.id)
    })

    const c = processCheckoutSession(await Radom.getCheckoutSession(checkoutId))
    const p = await Radom.getSellerProfile(c.organizationId)

    setSellerProfile(p)
    const cData = {
      ...c,
      sellerLogoUrl: '',
      sellerName: c.sellerAddress,
      products: c.products?.map(p => { return { product: p, quantity: 1 } }) ?? [],
      lineItems: c.items
    }
    setCheckoutData(cData as any)

    setCustomizations({
      leftPanelColor: c.customizations?.leftPanelColor ?? RADOM_COLORS.GRAY_MED,
      primaryButtonColor: c.customizations?.primaryButtonColor ?? RADOM_COLORS.ORANGE,
      slantedEdge: c.customizations?.slantedEdge ?? false,
      allowDiscountCodes: c.customizations?.allowDiscountCodes ?? false,
      displayQrCode: c.customizations?.displayQrCode ?? true
    })

    setTextColor(
      pickTextColorBasedOnBgColor(
        cData.customizations?.leftPanelColor ?? RADOM_COLORS.GRAY_MED,
        'rgba(255, 255, 255, 0.45)',
        'rgba(0, 0, 0, 0.45)'
      )
    )

    const products: ProductWithQuantity[] = []
    if (c.products) {
      c.products.forEach(p => {
        products.push({
          product: {
            ...p,
            chargingIntervalSeconds: p.chargingIntervalSeconds || 0
          },
          quantity: 1
        })
      })
    }
    if (c.items) {
      c.items.forEach(lineItem => {
        const lineProduct = {
          id: '',
          name: lineItem.name,
          description: '',
          chargingIntervalSeconds: lineItem.chargingIntervalSeconds || 0,
          price: lineItem.price,
          currency: lineItem.currency ?? 'USD',
          createdAt: new Date(),
          imageUrl: lineItem.imageUrl,
          updatedAt: new Date(),
          sellerAddress: checkoutData?.sellerAddress ?? ''
        }
        products.push({
          product: lineProduct as any,
          quantity: 1
        })
      })
    }
    setLines(products)

    if (c.expiresAt && (new Date(c.expiresAt) < new Date())) {
      setIsExpired(true)
      if (c.cancelUrl) {
        setTimeout(() => { window.location.href = c.cancelUrl ?? '' }, 1000)
      }
    }

    if (c.sessionStatus === 'cancelled') {
      setIsCancelled(true)
      if (c.cancelUrl) {
        setTimeout(() => { window.location.href = c.cancelUrl ?? '' }, 1000)
      }
    }

    if (c.sessionStatus === 'success' || c.sessionStatus === 'refunded') {
      setIsComplete(true)
      if (c.successUrl) {
        setTimeout(() => { window.location.href = c.successUrl ?? '' }, 3000)
      }
    }

    if (c.total && c.total > 0) {
      setIsSumPurchase(true)
      setTotalSum(c.total)
      setCurrency(c.currency ?? 'USD')
    } else {
      let currency = 'USD'
      if (subscriptionPurchases().length > 0) {
        currency = subscriptionPurchases()[0]?.product.currency ?? 'USD'
      } else if (cData.products && cData.products.length > 0) {
        currency = cData.products?.[0]?.product?.currency ?? 'USD'
      }
      setCurrency(currency)
      const productCurrencies = [...cData.items?.map(i => i.currency ?? 'USD'), ...cData.products?.map(p => p.product.currency)]
      Radom.getPriceQuotes(productCurrencies, [currency])
        .then(p => {
          // Get price quotes in terms of first product's currency
          const [total, subscriptionTotal] = getTotalSum(cData as any, p, currency)
          setTotalSum(total)
          setSubscriptionSum(subscriptionTotal)
        })
    }
  }

  const getPriceQuotes = (): void => {
    if (!checkoutData) {
      return
    }

    const productCurrencies = [...checkoutData.lineItems?.map(i => i.currency ?? 'USD') ?? ['USD'], ...checkoutData.products?.map(p => p.product.currency) ?? ['USD']]
    Radom.getPriceQuotes(productCurrencies, [currency])
      .then(p => {
        // Get price quotes in terms of first product's currency
        const [total, subscriptionTotal] = getTotalSum(checkoutData, p, currency)
        setTotalSum(total)
        setSubscriptionSum(subscriptionTotal)
      })
  }

  const onCheckout = async ({
    managed,
    completeTokenDelegate,
    subscriptionOptions
  }: CheckoutParams): Promise<void> => {
    if (managed) {
      if (!checkoutData) throw new Error('Something went wrong')
      if (!checkoutId) throw new Error('Something went wrong')

      setIsPreProcessingPayment(true)
      const { selectedMethod: method } = managed

      let paymentMethod: PaymentMethod = {
        managed: {
          method: {
            network: method.tokenInfo?.networkName ?? method.name,
            token: method.tokenInfo?.tokenAddress ?? undefined
          }
        }
      }

      if (subscriptionOptions) {
        if (subscriptionOptions.autopay) {
          if (!method.tokenInfo) {
            setErrorMessage('Cannot set up an autopay subscription without an EVM based token')
            return
          }

          paymentMethod = {
            managedTokenDelegate: {
              buyerAddress: subscriptionOptions.autopay.address,
              network: method.tokenInfo.networkName,
              token: method.tokenInfo.tokenAddress
            }
          }
        }

        if (subscriptionOptions.emailInvoice) {
          if (!subscriptionOptions.emailInvoice.emailAddress) {
            setErrorMessage('Cannot set up an email invoice subscription without an email address')
            return
          }
        }
      }

      try {
        const res = await Radom.createCheckoutSessionOrder({
          checkoutSessionId: checkoutId,
          paymentMethod,
          orderData: [],
          orderProductData: [],
          emailAddress: subscriptionOptions?.emailInvoice?.emailAddress.toLowerCase(),
          discountCode
        })

        setDiscountLocked(true)

        if (res.paymentMethodDetails.managed) {
          setManagedPaymentDetails(res.paymentMethodDetails.managed)
        } else if (res.paymentMethodDetails.managedTokenDelegate) {
          setTokenDelegatePaymentDetails(res.paymentMethodDetails.managedTokenDelegate)
        } else {
          throw new Error('Unexpected empty managed payment method details')
        }

        const checkPaymentInterval = setInterval(() => {
          if (!res.paymentMethodDetails.managed) {
            return
          }

          Radom.getManagedPaymentStatus(res.paymentMethodDetails.managed?.paymentId).then(res => {
            setPaymentStatus(res)
            if (res.status === 'paymentConfirmed') {
              if (checkoutData.successUrl && !posMode) {
                setTimeout(() => {
                  if (checkoutData.successUrl) {
                    window.location.href = checkoutData.successUrl
                  }
                }, 3000)
              }
              setIsSuccess(true)
              clearInterval(checkPaymentInterval)

              setSuccessElement(
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 10
                }}>
                  <CheckmarkWrapper>
                    <Checkmark width={30} height={30} animationDelay='0.5s' animationSpeed='0.75s' />
                  </CheckmarkWrapper>
                  <span style={{ fontSize: 18, color: RADOM_COLORS.BLACK }}>Payment successful</span>
                </div>
              )
            }
          }).catch(() => {})
        }, 1000)
      } catch (err) {
        console.error(err)
        setErrorMessage(err.reason || err.message)
        setDiscountLocked(false)
      }

      setIsPreProcessingPayment(false)
    }

    if (completeTokenDelegate) {
      const { paymentId, signature } = completeTokenDelegate

      setIsPreProcessingPayment(true)
      try {
        await Radom.completeTokenDelegatePayment(paymentId, signature)

        setIsPreProcessingPayment(false)
        setIsSuccess(true)

        setSuccessElement(
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 10
          }}>
            <CheckmarkWrapper>
              <Checkmark width={30} height={30} animationDelay='0.5s' animationSpeed='0.75s' />
            </CheckmarkWrapper>
            <span style={{ fontSize: 18, color: RADOM_COLORS.BLACK }}>Payment successful</span>
          </div>
        )

        setTimeout(() => {
          if (checkoutData?.successUrl) {
            window.location.href = checkoutData.successUrl
          }
        }, 3000)
      } catch (err) {
        setErrorMessage(err.reason || err.message)
      }
      setIsPreProcessingPayment(false)
    }
  }

  const onCancel = (): void => {
    if (checkoutData?.cancelUrl) {
      window.location.href = checkoutData.cancelUrl
    }
  }

  const subscriptionPurchases = (): ProductWithQuantity[] => {
    return lines.filter(l => l.product.chargingIntervalSeconds > 0)
  }

  const redeemDiscountCode = async (discountCode: string): Promise<void> => {
    if (!checkoutId) {
      return
    }

    setDiscountResult({ success: false, element: <Spinner /> })

    Radom.redeemDiscountCode({ checkoutSessionId: checkoutId, code: discountCode }).then((discount) => {
      setDiscount(discount)
      setDiscountResult({
        success: true,
        element: <div style={{ display: 'flex', gap: 10, alignItems: 'center', fontSize: 14 }}>
          <span>Discount applied!</span>
          <span style={{
            padding: 5,
            backgroundColor: RADOM_COLORS.SUCCESS,
            color: RADOM_COLORS.SUCCESS_LIGHT,
            borderRadius: 5,
            fontWeight: 600,
            fontSize: 12
          }}>
            {
              discount.model.modelType === 'percentage'
                ? new Intl.NumberFormat('en-US', {
                  style: 'percent',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                }).format(discount.model.amountOff / 100)
                : formatCurrency(currency, discount.model.amountOff)
            } OFF
          </span>
        </div>
      })
    }).catch(_ => {
      setDiscountResult({
        success: false,
        element: <span style={{ opacity: 0.5, fontSize: 12 }}>Invalid discount code</span>
      })
    })
  }

  useEffect(() => {
    document.title = 'Radom - Checkout'
    getCheckoutData()
      .finally(() => setIsLoading(false))
  }, [])

  useEffect(() => {
    const discountCode = searchParams.get('discountCode')
    if (discountCode) {
      setDiscountCode(discountCode)
      redeemDiscountCode(discountCode)
    }
  }, [searchParams])

  useEffect(() => {
    if (discountResult?.success) {
      setShowDiscountCodeInput(true)
    }
  }, [discountResult])

  if (posMode) {
    return <div style={{
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'white',
      zIndex: 100,
      display: 'flex',
      flexDirection: 'column',
      padding: '30px',
      alignItems: 'center',
      justifyContent: 'space-between',
      overflow: 'hidden'
    }}>

      <span style={{ fontWeight: 200 }}>
        {formatCurrency(currency, totalSum)}
      </span>

      {
        !isComplete && checkoutData &&
        <div style={{
          maxWidth: '600px',
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: 'auto'
        }}>
          <PayWidget
            currency={currency}
            checkoutData={checkoutData}
            isPreProcessingPayment={isPreProcessingPayment}
            isSuccess={isSuccess}
            onCheckout={onCheckout}
            managedPaymentDetails={managedPaymentDetails}
            onQuoteUpdate={() => getPriceQuotes()}
            errorMessage={errorMessage}
            successElement={successElement}
            paymentStatus={paymentStatus}
            posMode />
        </div>
      }

    </div>
  }

  return <Container>
    {!isLoading && !checkoutData && <div>Checkout id not found</div>}
    {
      checkoutData &&
      <>
        <div style={{ width: '100%', height: '100%' }}>
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', height: '100%' }}>
            <LeftSection
              slantedEdge={checkoutData.customizations?.slantedEdge}
              leftPanelColor={checkoutData.customizations?.leftPanelColor ?? RADOM_COLORS.GRAY_MED}>
              <div style={{
                padding: '20px 10px',
                fontSize: 14,
                display: 'flex',
                width: '100%',
                alignItems: 'center'
              }}>
                {
                  checkoutData.cancelUrl &&
                        <IconButton onClick={onCancel} style={{
                          padding: 10,
                          display: 'flex',
                          alignItems: 'center',
                          gap: 5,
                          fontSize: 14
                        }}>
                          <Arrow stroke={textColor} style={{
                            width: 20,
                            height: 15,
                            transform: 'rotate(180deg)'
                          }} />
                        </IconButton>
                }
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 8,
                  alignItems: 'center',
                  color: textColor
                }}>
                  {
                    sellerProfile?.logoUrl &&
                          <img src={sellerProfile?.logoUrl} style={{ width: 'auto', height: 25, pointerEvents: 'none' }} />
                  }
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                    {sellerProfile?.name
                      ? <span>{sellerProfile.name}</span>
                      : <span>{shortAddress(checkoutData.sellerAddress, 5)}</span>
                    }
                  </div>
                </div>

              </div>
              <LeftSectionInner
                isSumPurchase={
                  checkoutData.organizationId === 'f58cf6b3-b87d-4893-b859-cddc85ac0abf' ||
                  isSumPurchase || (lines.length === 1 && lines[0].product.chargingIntervalSeconds > 0)
                }>

                {
                  checkoutData.organizationId === 'f58cf6b3-b87d-4893-b859-cddc85ac0abf' &&
                  subscriptionPurchases().length === 0 &&
                  checkoutData.products &&
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 10,
                    maxWidth: '400px',
                    alignSelf: 'center',
                    justifyContent: 'center'
                  }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      { checkoutData.products.length > 1 &&
                        <span style={{ fontSize: 28 }}>
                          Pay {checkoutData.sellerName}
                        </span>
                      }
                      { checkoutData.products.length === 1 &&
                        <span style={{ fontSize: 28 }}>{checkoutData.products[0]?.product.name}</span> }
                      { checkoutData.products?.length === 0 && <span style={{ fontSize: 28 }}>Product name</span> }
                      {
                        checkoutData.products?.length === 1 && checkoutData.products[0]?.product.description &&
                      <span style={{ fontSize: 14, opacity: 0.5, whiteSpace: 'pre' }}>
                        {checkoutData.products[0]?.product.description}
                      </span>
                      }
                    </div>
                    {
                      isLoading && <Spinner />
                    }
                    {
                      !isLoading && <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center', fontSize: 54, fontWeight: 100 }}>
                        {formatCurrency(currency, totalSum)}
                      </div>
                    }
                    {
                      checkoutData.products?.length === 1 && checkoutData.products[0]?.product.imageUrl &&
                      <img style={{
                        marginTop: 10,
                        maxWidth: 200,
                        maxHeight: 200
                      }} src={checkoutData.products[0]?.product.imageUrl} />
                    }
                  </div>
                }

                {
                  checkoutData.organizationId !== 'f58cf6b3-b87d-4893-b859-cddc85ac0abf' &&
                  <div style={{ fontSize: 40, wordWrap: 'break-word' }}>
                    <div style={{ fontSize: 20 }}>
                      {
                        subscriptionPurchases().length === 0 &&
                          <div style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            overflowWrap: 'anywhere',
                            gap: 5,
                            fontSize: 22
                          }}>
                            <span style={{ opacity: 0.65, fontWeight: 100 }}>Pay</span>
                            <span style={{
                              fontWeight: sellerProfile?.name ? 300 : 100
                            }}>
                              {sellerProfile?.name ? sellerProfile.name : shortAddress(checkoutData.sellerAddress, 8)}
                            </span>
                          </div>
                      }
                      {
                        subscriptionPurchases().length > 0 && <>
                          <span style={{ fontSize: 28, display: 'flex', flexDirection: 'column' }}>
                            <span style={{ fontWeight: 100, fontSize: 18, opacity: 0.5 }}>Subscribe to</span>
                            {subscriptionPurchases()[0].product.name}
                          </span>
                        </>
                      }
                    </div>
                    <div>
                      { isLoading && <Spinner /> }
                      {
                        !isLoading && <div style={{ fontWeight: 200 }}>
                          <span style={{ fontSize: 48 }}>{formatCurrency(currency, totalSum)}</span>
                          {
                            subscriptionPurchases().length > 0 &&
                              subscriptionPurchases().length === checkoutData?.products?.length &&
                              <div style={{ display: 'inline-block', marginLeft: '4px', lineHeight: '14px', fontSize: '14px' }}>
                                <div>per</div>
                                <div>{
                                  formatSubscriptionDuration(subscriptionPurchases()[0].product.chargingIntervalSeconds)
                                }</div>
                              </div>
                          }
                        </div>
                      }
                      {
                        subscriptionPurchases().length > 0 &&
                          subscriptionPurchases().length !== checkoutData?.products?.length &&
                          <div style={{ fontSize: 14, color: textColor }}>
                            then {
                              formatCurrency(currency, subscriptionSum)
                            } per {
                              formatSubscriptionDuration(subscriptionPurchases()[0].product.chargingIntervalSeconds)
                            }
                          </div>
                      }
                    </div>
                  </div>
                }
                {
                  checkoutData.organizationId !== 'f58cf6b3-b87d-4893-b859-cddc85ac0abf' &&
                  !isSumPurchase &&
                    <div style={lines.length === 1 && lines[0].product.chargingIntervalSeconds > 0 ? {} : { width: '100%' }}>
                      <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 20,
                        width: '100%',
                        maxHeight: 250,
                        overflow: 'scroll',
                        justifyContent: 'space-between'
                      }}>
                        {
                          lines.length === 1 &&
                          lines[0].product.chargingIntervalSeconds > 0 &&
                          lines[0].product.imageUrl &&
                            <img style={{
                              marginTop: 10,
                              maxWidth: 200,
                              maxHeight: 200,
                              borderRadius: 5
                            }} src={lines[0].product.imageUrl} />
                        }
                        {
                          (lines.length > 1 ||
                            (lines.length === 1 && lines[0].product.chargingIntervalSeconds === 0)) &&
                          lines.map((p, i) =>
                            <div key={i} style={{
                              fontSize: 14,
                              display: 'flex',
                              width: '100%',
                              justifyContent: 'space-evenly',
                              alignItems: 'center',
                              gap: 20
                            }}>
                              {
                                p.product.imageUrl &&
                                <img style={{ width: 30, height: 30 }} src={p.product.imageUrl} />
                              }
                              <div style={{ flexGrow: 1 }}>
                                <div style={{ marginBottom: 4 }}>{p.product.name}</div>
                                <div style={{ fontSize: 12, opacity: 0.5 }}>
                                  Qty {p.quantity}
                                  {/* <QuantityInput disabled={isSuccess || isLoading || isExpired} type="number" min="1" value={p.quantity} onChange={e => handleQuantityChange(e, i)} /> */}
                                </div>
                              </div>
                              <div style={{
                                alignSelf: 'start',
                                textAlign: 'right',
                                display: 'flex',
                                flexDirection: 'column'
                              }}>
                                {
                                  isLoading && <Spinner />
                                }
                                {
                                  !isLoading && <>
                                    <span>
                                      {formatCurrency(p.product.currency, p.product.price * p.quantity)}
                                      {
                                        p.product.chargingIntervalSeconds > 0 &&
                                        <span style={{ fontSize: '14px', color: textColor }}> / {formatSubscriptionDuration(p.product.chargingIntervalSeconds).toLowerCase()}</span>
                                      }
                                    </span>
                                    { p.quantity > 1 &&
                                      <span style={{ fontSize: '14px', textAlign: 'right', color: textColor }}>
                                        {formatCurrency(p.product.currency, p.product.price)} each
                                      </span>
                                    }
                                  </>
                                }
                              </div>
                            </div>
                          )
                        }
                      </div>
                    </div>
                }

                {
                  checkoutData.customizations?.allowDiscountCodes && !showDiscountCodeInput && !discountLocked &&
                  <IconButton
                    onClick={() => { setShowDiscountCodeInput(true) }}
                    style={{
                      marginTop: 10,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2,
                      padding: 0,
                      color: pickTextColorBasedOnBgColor(customizations?.leftPanelColor, 'rgba(255, 255, 255, 0.5)', 'rgba(0, 0, 0, 0.5)')
                    }}>
                    <span>Add discount code</span>
                    <Arrow stroke={pickTextColorBasedOnBgColor(customizations?.leftPanelColor, 'rgba(255, 255, 255, 0.5)', 'rgba(0, 0, 0, 0.5)')} height={15} />
                  </IconButton>
                }
                {
                  showDiscountCodeInput &&
                  <div style={{ marginTop: 10, display: 'flex', flexDirection: 'column', gap: 10 }}>
                    {
                      !discountResult?.success && !discountLocked &&
                      <div style={{
                        borderRadius: 5,
                        border: '1px solid',
                        backgroundColor: pickTextColorBasedOnBgColor(customizations.leftPanelColor, 'rgba(255, 255, 255, 0.1)', 'rgba(255, 255, 255, 0.25)'),
                        color: pickTextColorBasedOnBgColor(customizations.leftPanelColor, 'white', 'black'),
                        borderColor: pickTextColorBasedOnBgColor(customizations.leftPanelColor, 'rgba(255, 255, 255, 0.1)', 'rgba(0, 0, 0, 0.1)'),
                        display: 'flex',
                        alignItems: 'center'
                      }}>

                        <TextInput
                          autoFocus
                          placeholder='Enter code'
                          autoCorrect="off"
                          spellCheck="false"
                          type="text"
                          onChange={(e) => setDiscountCode(e.target.value)}
                          placeholderColor={pickTextColorBasedOnBgColor(customizations.leftPanelColor, 'rgba(255, 255, 255, 0.25)', 'rgba(0, 0, 0, 0.25)')}
                          style={{
                            color: pickTextColorBasedOnBgColor(customizations.leftPanelColor, 'white', 'black'),
                            background: 'none',
                            border: 'none',
                            boxShadow: 'none'
                          }}
                        />
                        <SecondaryButton
                          onClick={async () => await redeemDiscountCode(discountCode)}
                          style={{
                            padding: '3px 10px',
                            textTransform: 'uppercase',
                            color: pickTextColorBasedOnBgColor(customizations.leftPanelColor, 'white', 'black'),
                            border: 'none'
                          }}>Apply</SecondaryButton>
                      </div>
                    }
                    <div style={{ display: 'flex', gap: 10 }}>
                      {discountResult?.element}
                      {discountResult?.success && !discountLocked &&
                      <IconButton
                        style={{ padding: 5 }}
                        onClick={() => {
                          setDiscountResult({ success: false, element: <></> })
                          setDiscount(undefined)
                          setShowDiscountCodeInput(false)
                        }}>
                        <Close
                          width={15}
                          height={15}
                          stroke={pickTextColorBasedOnBgColor(customizations.leftPanelColor, 'white', 'black')}
                          style={{ opacity: 0.5 }} />
                      </IconButton>
                      }
                    </div>
                  </div>
                }
              </LeftSectionInner>
              <div style={{
                position: 'relative',
                padding: 30,
                fontSize: '14px',
                alignSelf: 'start'
              }}>
                <a
                  href='https://radom.com'
                  target='_blank'
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 6,
                    cursor: 'pointer',
                    userSelect: 'none',
                    fontSize: 14,
                    whiteSpace: 'nowrap',
                    color: textColor,
                    fontWeight: 100,
                    textDecoration: 'none'
                  }} rel="noreferrer">
                  <span>Checkout by</span>
                  <RadomLogo
                    width={50}
                    fill={textColor}
                  />
                </a>
              </div>
            </LeftSection>

            <RightSection>
              <div style={{
                maxWidth: '400px',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: 'auto'
              }}>
                {
                  isComplete &&
                    <div>
                      <div>Session complete{ checkoutData.successUrl && <span>, redirecting...</span>}</div>
                    </div>
                }
                { !isComplete && isExpired && !isCancelled &&
                  <div>
                    <div>Session expired{ checkoutData.cancelUrl && <span>, redirecting...</span>}</div>
                  </div>
                }
                { isCancelled &&
                  <div>
                    <div>Session cancelled{ checkoutData.cancelUrl && <span>, redirecting...</span>}</div>
                  </div>
                }
                {
                  !isComplete && !isExpired && !isCancelled &&
                    <PayWidget
                      currency={currency}
                      checkoutData={checkoutData}
                      isPreProcessingPayment={isPreProcessingPayment}
                      isSuccess={isSuccess}
                      onCheckout={onCheckout}
                      managedPaymentDetails={managedPaymentDetails}
                      tokenDelegatePaymentDetails={tokenDelegatePaymentDetails}
                      onQuoteUpdate={() => getPriceQuotes()}
                      errorMessage={errorMessage}
                      discount={discount}
                      successElement={successElement}
                      paymentStatus={paymentStatus} />
                }
              </div>
            </RightSection>
          </div>
        </div>
      </>
    }
  </Container>
}

export default HostedCheckout
